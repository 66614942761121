import { ReferralShareInformation } from "components/shared/ReferralShareInfo";

import { Box } from "components/shared/Box";
import { useUserContext } from "context/user.context";
import { Button } from "components/shared/Button";
import { useQuery } from "@tanstack/react-query";
import { getReferrals } from "requests";
import { Loader } from "components/shared/Loader";
import { Link } from "react-router-dom";
import { ReferralGraphTree } from "components/ReferralGraphTree";
import { UserInfoBlock } from "components/UserInfoBlock";
import { useToggle } from "hooks";
import { ChevronDown, InfoIcon } from "components/shared/Icons";
import classNames from "classnames";
import { useForm, FormProvider } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { unlockWithdrawProfit } from "requests";

const ReferallPageStats = () => {
  const { user } = useUserContext();
  const [showLeftInactive, toggleLeftInactive] = useToggle();
  const [showRightInactive, toggleRightInactive] = useToggle();

  const methods = useForm({
    defaultValues: {
      query: "",
      selectedTreeUser: null,
    },
  });

  methods.watch("selectedTreeUser");

  const { isLoading: isUnlockingProfit, mutate } = useMutation({
    mutationFn: unlockWithdrawProfit,
    onSuccess: () => {
      toast.success("Profit were withdrawn");
    },
    onError: (error) => {
      // @ts-expect-error
      const errResponse = error.response;

      if (errResponse.status === 418) {
        toast.error(errResponse.data.message);
      } else {
        toast.error("Error while withdrawing");
      }
    },
  });

  const handleWithdrawProfit = () => {
    mutate();
  };

  const { data: referrals, isLoading: isReferralsLoading } = useQuery({
    queryKey: ["referrals"],
    queryFn: getReferrals,
  });

  const {
    data: referralsForUserStats,
    isLoading: isReferralsLoadingUserStats,
  } = useQuery({
    queryKey: ["referralsStats", methods.getValues("selectedTreeUser")],
    queryFn: getReferrals,
  });

  if (isReferralsLoading) return <Loader />;

  const availableBinaryBonus = (user && +user.availableBinaryBonus) ?? 0;

  return (
    <FormProvider {...methods}>
      <div className="md:grid grid-cols-1 gap-5 md:grid-cols-6">
        <Box className="col-span-3 !p-0 grid grid-cols-1 md:grid-cols-2 mb-5 md:mb-0">
          <div className="col-span-1 border-lightBlack border-b md:border-b-0 md:border-r p-5">
            <h2 className="text-[#9B9B9B] text-sm mb-2">
              Total referral bonuses
            </h2>
            <p className="text-main font-semibold text-xl">
              ${user?.totalReferralBonuses}
            </p>
            <p className="text-[#9B9B9B] text-sm mt-2 mb-2">
              Available for claim
            </p>
            <p className="text-main font-semibold text-xl">
              ${user?.availableBinaryBonus}
            </p>

            <Button
              type="button"
              className="bg-main mt-5 md:mt-[40px] font-semibold text-black disabled:opacity-85"
              onClick={handleWithdrawProfit}
              isLoading={isUnlockingProfit}
              disabled={availableBinaryBonus < 10}
            >
              Claim Binary Bonus
            </Button>
            {availableBinaryBonus <= 10 && (
              <div className="flex items-center mt-3">
                <p className="text-[10px] font-semibold text-grey mr-[6px]">
                  Available after accumulating $10 or more
                </p>
                <InfoIcon />
              </div>
            )}

            <div
              className={classNames(
                "bg-lightBlack mt-4 font-semibold text-white text-center p-3 rounded-lg"
              )}
            >
              Your LVL: TIER {user?.membershipStatus}
            </div>
          </div>
          <div className="grid grid-cols-2">
            <div className="col-span-1 border-b border-lightBlack p-5">
              <h2 className="text-[#9B9B9B] text-sm mb-2">Binary income</h2>
              <p className="text-white font-semibold">${user?.binaryIncome}</p>
            </div>
            <div className="col-span-1 p-5 border-b border-l border-lightBlack">
              <h2 className="text-[#9B9B9B] text-sm mb-2">Direct bonus</h2>
              <p className="text-white font-semibold">${user?.directBonus}</p>
            </div>
            <div className="col-span-1 p-5">
              <h2 className="text-[#9B9B9B] text-sm mb-2">Binary turnover</h2>
              <p className="text-white font-semibold">
                ${user?.binaryTurnover}
              </p>
            </div>
            <div className="col-span-1 p-5 border-l border-lightBlack">
              <h2 className="text-[#9B9B9B] text-sm mb-2">
                Your senior partner
              </h2>
              <p className="text-white font-semibold">
                {user?.referred_by ?? "-"}
              </p>
            </div>
          </div>
        </Box>

        <div className="col-span-1 md:col-span-3">
          {+(user?.depositedAmount ?? 0) > 0 ? (
            <>
              <ReferralShareInformation
                withButton
                link={`https://smart-x.world?ref=${user?.referral_codeL}`}
                buttonText="Share referral link L"
              />
              <div className="mt-5">
                <ReferralShareInformation
                  withButton
                  link={`https://smart-x.world?ref=${user?.referral_codeR}`}
                  buttonText="Share referral link R"
                />
              </div>
            </>
          ) : (
            <p className="mt-10 mb-10 text-center text-main">
              In order to receive referral links, make first{" "}
              <Link to="/finance">
                <strong>deposit</strong>{" "}
              </Link>
            </p>
          )}
        </div>

        <Box className="col-span-1 border-none !p-0 md:col-span-3 mb-5 mt-5">
          <div
            className="flex items-center justify-between cursor-pointer"
            onClick={toggleLeftInactive}
          >
            <h3 className="text-lGrey font-semibold">
              Inactive referrals left
            </h3>
            <div
              className={classNames(
                "ml-[10px] transition-all",
                showLeftInactive ? "rotate-0" : "rotate-180"
              )}
            >
              <ChevronDown />
            </div>
          </div>
          {showLeftInactive && (
            <div className="max-h-[400px] overflow-y-auto pr-5">
              {referrals.data.referredByTmpLeft.map((left: any) => {
                return (
                  <div className="flex justify-between bg-lightBlack p-5 rounded-2xl mt-5">
                    <UserInfoBlock
                      key={left.id}
                      showIcon={true}
                      username={left.login}
                      email={left.email}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </Box>
        <Box className="col-span-1 border-none !p-0 md:col-span-3 mb-5 md:mt-5">
          <div
            className="flex items-center justify-between cursor-pointer"
            onClick={toggleRightInactive}
          >
            <h3 className="text-lGrey font-semibold ">
              Inactive referrals right{" "}
            </h3>
            <div
              className={classNames(
                "ml-[10px] transition-all",
                showRightInactive ? "rotate-0" : "rotate-180"
              )}
            >
              <ChevronDown />
            </div>
          </div>
          {showRightInactive && (
            <div className="max-h-[400px] overflow-y-auto pr-5">
              {referrals.data.referredByTmpRight.map((left: any) => {
                return (
                  <div className="flex justify-between bg-lightBlack p-5 rounded-2xl mt-5">
                    <UserInfoBlock
                      key={left.id}
                      showIcon={true}
                      username={left.login}
                      email={left.email}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </Box>

        <Box className="col-span-1 md:col-span-6">
          <ReferralGraphTree
            tree={referrals.data.tree}
            binaryStats={referralsForUserStats}
            isReferralsLoadingUserStats={isReferralsLoadingUserStats}
          />
        </Box>
      </div>
    </FormProvider>
  );
};

export default ReferallPageStats;

import {
  createContext,
  FC,
  useMemo,
  useContext,
  useEffect,
  useCallback,
  useState,
} from "react";
import Web3 from "web3";
import { useAccount, useWalletClient } from "wagmi";
import { useNavigate } from "react-router";
import { useAuth } from "hooks";
import { AxiosResponse } from "axios";
import {
  UseMutateFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { toast } from "react-toastify";

import {
  getUser,
  updateUserInfo,
  updateUserAvatar,
  getAppSettings,
} from "requests";
import { SmartContractUserAccount, UpdateUserProps, UserProps } from "types";
import { errorParser, formatNumberFromWei } from "utils";
import { ABIDeposit, SmartXLogicAbi } from "const";

interface UserContextProps {
  user: UserProps | null;
  isProfileLoading: boolean;
  profileError: any;
  updateUser: UseMutateFunction<
    AxiosResponse<any, any>,
    unknown,
    Partial<UpdateUserProps>,
    unknown
  >;
  isUserUpdating: boolean;
  updatingError: any;
  isUserAvatarUpdating: boolean;
  updatingAvatarError: any;
  mutateUserAvatar: UseMutateFunction<
    AxiosResponse<any, any>,
    unknown,
    Partial<any>,
    unknown
  >;
  depositFunds: any;
  appSettings: {
    smartContractAddress: string;
    walletAddress: string;
  };
  isAppSettingsLoading: boolean;
  withdrawReferralProfit: any;
  isLoadingClaimingBinaryBonus: boolean;
  refetchUserProfile: any;
}

const initialCtx = {
  user: null,
  isProfileLoading: false,
  profileError: null,
  updateUser: () => {},
  isUserUpdating: false,
  updatingError: null,
  isUserAvatarUpdating: false,
  updatingAvatarError: null,
  mutateUserAvatar: () => {},
  tron: null,
  tronLoading: true,
  depositFunds: () => {},
  appSettings: {
    smartContractAddress: "",
    walletAddress: "",
  },
  isAppSettingsLoading: true,
  withdrawReferralProfit: () => {},
  isLoadingClaimingBinaryBonus: false,
  refetchUserProfile: () => {},
};

export const UserContext = createContext<UserContextProps>(initialCtx);

export const UserContextProvider: FC<any> = ({ children }) => {
  const walletClient = useWalletClient();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { token } = useAuth();
  const { address } = useAccount();
  const [isLoadingClaimingBinaryBonus, setIsLoadingClaimingBinaryBonus] =
    useState(false);

  const {
    data: user,
    isLoading: isProfileLoading,
    error: profileError,
    refetch,
  } = useQuery({
    queryKey: ["user"],
    queryFn: getUser,
    enabled: !!token,
  });

  useEffect(() => {
    if (walletClient.isError) {
      walletClient.refetch();
    }
  }, [walletClient]);

  const { data: appSettings, isLoading: isAppSettingsLoading } = useQuery({
    queryKey: ["applicationSettings"],
    queryFn: getAppSettings,
  });
  console.log(user, "seru");
  const {
    isLoading: isUserUpdating,
    error: updatingError,
    mutate: updateUser,
  } = useMutation({
    mutationFn: updateUserInfo,
    onSuccess: async ({ data }) => {
      await queryClient.invalidateQueries({ queryKey: ["user"], exact: true });

      toast(data.message);

      navigate("/");
    },
    onError: (error) => {
      // @ts-expect-error
      toast(errorParser(error) ?? "Error while making request");
    },
  });

  const {
    isLoading: isUserAvatarUpdating,
    error: updatingAvatarError,
    mutate: mutateUserAvatar,
  } = useMutation({
    mutationFn: updateUserAvatar,
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({ queryKey: ["user"], exact: true });
      toast(data.message);
      refetch();
    },
    onError: () => {
      toast("Error while updating user, please, try later");
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const refetchUserProfile = () => {
    queryClient.invalidateQueries({ queryKey: ["user"], exact: true });
    refetch();
  };

  const withdrawReferralProfit = useCallback(
    async (
      claimedProfitAmount: number,
      lastProfitClaimedAt: number,
      referralBonusAmount: number
    ) => {
      setIsLoadingClaimingBinaryBonus(true);
      try {
        if (walletClient.data?.transport) {
          const web3 = new Web3(walletClient.data.transport);

          // if first time use 'register' from contract to know this use accounts method from it
          const smartXContract = new web3.eth.Contract(
            SmartXLogicAbi,
            appSettings.smartContractAddress
          );

          const txFromWithdrawMethod = await smartXContract.methods
            .withdraw(
              claimedProfitAmount,
              lastProfitClaimedAt,
              referralBonusAmount
            )
            .send({
              from: user.address,
              gas: "0x3d090",
              gasPrice: "0x12a05f200",
              value: "0x0",
            });

          if (txFromWithdrawMethod) {
            toast.success("Funds were claimed");
            await queryClient.invalidateQueries({
              queryKey: ["user"],
              exact: true,
            });
          } else {
            toast.error("Error whie claiming funds");
          }
        }
      } catch (error) {
        console.error(error);
        toast.error("Error while withdrawing, please, contact support");
      } finally {
        setIsLoadingClaimingBinaryBonus(false);
      }
    },
    [walletClient, appSettings, user, queryClient]
  );

  const depositFunds = useCallback(
    async (amount: number) => {
      try {
        if (walletClient.data?.transport) {
          const web3 = new Web3(walletClient.data.transport);

          const smartXContract = new web3.eth.Contract(
            SmartXLogicAbi,
            appSettings.smartContractAddress
          );

          const userInfoAccounts: SmartContractUserAccount =
            await smartXContract.methods.accounts(user.address).call();

          const lastTimeProfitClaimed = Number(
            userInfoAccounts.lastProfitClaimedAt
          );
          const depositedAmount = formatNumberFromWei(
            web3,
            userInfoAccounts.depositedAmount
          );

          const isFirstTimeDeposit =
            lastTimeProfitClaimed === 0 && depositedAmount === 0;

          const contract = new web3.eth.Contract(
            ABIDeposit,
            appSettings.walletAddress
            // "0x55d398326f99059ff775485246999027b3197955"
          );

          const bigIntValue = await contract.methods
            .balanceOf(user.address)
            .call();

          const balance = formatNumberFromWei(web3, bigIntValue as any);

          if (balance < 10 || amount > balance) {
            toast.error("Wrong amount entered");
            // TODO: add throw error case
            return;
          }

          const amountToDeposit = amount * Math.pow(10, 18);

          const approve = contract.methods.approve(
            appSettings.smartContractAddress,
            // "0x01C90D9A9336979499618B1f508b76A24A79d166",
            amountToDeposit
          );
          const dataDeposit = approve.encodeABI();

          await web3.eth.sendTransaction({
            to: appSettings.walletAddress,
            // to: "0x55d398326f99059ff775485246999027b3197955",
            from: address,
            gas: "0x3d090",
            value: "0x0",
            data: dataDeposit,
            gasPrice: "0x12a05f200",
          });

          if (isFirstTimeDeposit) {
            const txFromFirstDeposit = await smartXContract.methods
              .register(
                user.referred_by_address ??
                  "0x0000000000000000000000000000000000000000",
                parseInt(user.referredBySide ?? 0),
                amountToDeposit
              )
              .send({
                from: user.address,
                gas: "0x3d090",
                gasPrice: "0x12a05f200",
                value: "0x0",
              });

            if (txFromFirstDeposit) {
              toast.success("Funds were deposited");
            } else {
              toast.error("Error whie sending funds");
            }
            return;
          } else {
            const txFromDepositMethod = await smartXContract.methods
              .deposit(amountToDeposit)
              .send({
                from: user.address,
                gas: "0x3d090",
                gasPrice: "0x12a05f200",
                value: "0x0",
              });

            if (txFromDepositMethod) {
              toast.success("Funds were deposited");
            } else {
              toast.error("Error whie sending funds");
            }
          }
        }
      } catch (error) {
        console.error(error);

        toast.error("Check if you have enough funds");
      }
    },
    [walletClient, address, appSettings, user]
  );

  const value = useMemo(
    () => ({
      user,
      isProfileLoading,
      profileError,
      updateUser,
      isUserUpdating,
      updatingError,
      isUserAvatarUpdating,
      updatingAvatarError,
      mutateUserAvatar,
      depositFunds,
      appSettings,
      isAppSettingsLoading,
      withdrawReferralProfit,
      isLoadingClaimingBinaryBonus,
      refetchUserProfile,
    }),
    [
      user,
      isProfileLoading,
      profileError,
      updateUser,
      isUserUpdating,
      updatingError,
      isUserAvatarUpdating,
      updatingAvatarError,
      mutateUserAvatar,
      appSettings,
      isAppSettingsLoading,
      depositFunds,
      withdrawReferralProfit,
      isLoadingClaimingBinaryBonus,
      refetchUserProfile,
    ]
  );
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUserContext = () => useContext(UserContext);

/* eslint-disable @typescript-eslint/no-unused-vars */
import { useLocation, useNavigate } from "react-router-dom";

import classNames from "classnames";
import { useToggle } from "hooks";

interface SidebarItem {
  item: { title: string; link: string; icon: any; childrens?: any[] };
  toggleSidebar: () => void;
}

export const SidebarToggleItem = ({ item, toggleSidebar }: SidebarItem) => {
  const navigate = useNavigate();
  const location = useLocation();

  // in case if childrens should be hightlighted too, use [2] index
  // pass related styles to it
  const isActivePage =
    location.pathname.split("/")[1] === item.link.split("/")[1];

  const { title, link, icon, childrens } = item;

  const [open, toggleChildrens] = useToggle(false);

  const handleItemClick = (childrenLink?: string) => {
    navigate(link);
  };

  return (
    <li
      className={classNames(
        "py-4 px-5 text-sidebarText flex flex-col justify-center relative",
        isActivePage && "bg-sidebar"
      )}
    >
      {isActivePage && (
        <div className="absolute bg-main right-0 h-full w-[3px]" />
      )}
      <div
        className="flex cursor-pointer items-center w-full"
        onClick={() => handleItemClick()}
      >
        <div
          className={classNames(
            "mr-7 [&>svg]:fill-grey",
            isActivePage && "[&>svg]:fill-lGrey"
          )}
        >
          {icon}
        </div>
        <span
          className={classNames(
            "pointer-events-none text-sm",
            isActivePage && "text-lGrey"
          )}
        >
          {title}
        </span>
      </div>
    </li>
  );
};

import {
  ComputerIcon,
  FinanceFaqIcon,
  ReferralProgrammIcon,
} from "components/shared/Icons";
import { Accordion } from "components/shared/Accordion";
import {
  REFERRAL_FAQ,
  FINANCE_FAQ,
  GENERAL_FAQ,
  faqBlocksContacts,
} from "const";
import { cloneElement, useState } from "react";
import classNames from "classnames";

const TAB_TYPES = {
  GENERAL: "gen",
  REFERRAL: "referral",
  FINANCE: "finance",
} as const;

export default function FaqPage() {
  const [faqType, setFaqType] = useState<any>(TAB_TYPES.GENERAL);

  const renderQuestionsArray = () => {
    if (TAB_TYPES.REFERRAL === faqType) return REFERRAL_FAQ;

    if (TAB_TYPES.FINANCE === faqType) return FINANCE_FAQ;

    return GENERAL_FAQ;
  };

  return (
    <div className="pb-8">
      <div className="mb-9 text-white">
        <h2 className="text-6xl font-bold">FAQ</h2>
        <p className="md:text-2xl">Questions & answers</p>
      </div>
      <ul className="flex md:items-center flex-col md:flex-row md:[&>*]:ml-4 mb-[72px]">
        <li
          className="flex md:items-center md:justify-center mt-2 md:mt-0 !ml-0 cursor-pointer"
          onClick={() => setFaqType(TAB_TYPES.GENERAL)}
        >
          <div
            className={classNames(
              faqType === TAB_TYPES.GENERAL
                ? "[&>svg]:!fill-lYellow"
                : "[&>svg]:!fill-defaultText"
            )}
          >
            <ComputerIcon />
          </div>
          <span
            className={classNames(
              "ml-3",
              faqType === TAB_TYPES.GENERAL && "text-lYellow"
            )}
          >
            General
          </span>
        </li>
        <li
          className="flex md:items-center md:justify-center mt-2 md:mt-0 cursor-pointer"
          onClick={() => setFaqType(TAB_TYPES.REFERRAL)}
        >
          <div
            className={classNames(
              faqType === TAB_TYPES.REFERRAL
                ? "[&>svg]:!fill-lYellow"
                : "[&>svg]:!fill-defaultText"
            )}
          >
            <ReferralProgrammIcon />
          </div>
          <span
            className={classNames(
              "ml-3",
              faqType === TAB_TYPES.REFERRAL && "text-lYellow"
            )}
          >
            Referral Program
          </span>
        </li>
        <li
          className="flex md:items-center md:justify-center mt-2 md:mt-0 cursor-pointer"
          onClick={() => setFaqType(TAB_TYPES.FINANCE)}
        >
          <div
            className={classNames(
              faqType === TAB_TYPES.FINANCE
                ? "[&>svg]:!fill-lYellow"
                : "[&>svg]:!fill-defaultText"
            )}
          >
            <FinanceFaqIcon />
          </div>
          <span
            className={classNames(
              "ml-3",
              faqType === TAB_TYPES.FINANCE && "text-lYellow"
            )}
          >
            Finance
          </span>
        </li>
      </ul>
      <div className="max-w-[670px] [&>*]:mt-4 mb-[120px]">
        {renderQuestionsArray().map((faqInfo) => {
          return (
            <Accordion
              key={faqInfo.title}
              text={faqInfo.body}
              title={faqInfo.title}
            />
          );
        })}
      </div>
      <div className="mb-12 relative">
        <h3 className="md:text-[60px] mb-10 font-bold text-white">Contacts</h3>
        <p className="text-lYellow">
          If you do not find the information you are looking for, please contact
          us:
        </p>
      </div>
      <div className="grid grid-cols-2 md:flex md:flex-row md:grid-cols-5 gap-6">
        {faqBlocksContacts.map((socialInfo) => {
          return (
            <a
              key={socialInfo.title}
              href={socialInfo.link}
              className="contactsFaqBlock flex flex-col border border-[#2C3138] rounded-2xl items-center  md:h-[200px] md:w-[200px]"
              target="_blank"
              rel="noreferrer"
            >
              <div
                className={classNames(
                  socialInfo.title === "Facebook" &&
                    "[&>svg]:h-[80px] flex items-center justify-center mb-5 mt-5"
                )}
              >
                {cloneElement(socialInfo.icon)}
              </div>
              <h4 className="uppercase">{socialInfo.title}</h4>
            </a>
          );
        })}
      </div>
    </div>
  );
}
